import { useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import { createCheckout, updateCheckout } from 'store/cart/actions'
import { sortByVendor } from 'services/cart'
import { formatPriceString } from 'services/price'
import { strapi } from 'services/strapi'
import { Vendor } from 'etc/strapi-types'
import { VENDORS } from 'etc/queries'
import { Button, Col, Divider, Row, Typography } from 'antd'
import { CloseSquareOutlined } from '@ant-design/icons'
import Spacer from 'components/spacer'
import MinimumOrderValueAlert from 'components/cart/minimum-order-value-alert'
import VendorInfo from './vendor-info'
import Item from './item'
import styles from './cart-widget.module.less'

const { Text } = Typography

interface Props {
	visible: boolean
	hide: () => void
}

const CartWidget = ({ visible, hide }: Props) => {
	const dispatch = useDispatch()

	const items = useSelector((state: RootState) => state.cart.items)
	const checkout = useSelector((state: RootState) => state.cart.checkout)
	const ready = useSelector((state: RootState) => state.cart.ready)
	const discountApplications = useSelector(
		(state: RootState) => state.cart.checkout?.discountAllocations
	)

	const [vendors, setVendors] = useState<Pick<Vendor, 'slug' | 'name' | 'minimumOrderValue'>[]>()

	const couponItems = Object.values(items).filter(item => item.is_coupon)
	const sortedByVendor = sortByVendor(items)
	const isEmpty = Object.values(items).length === 0

	const timeout = useRef<NodeJS.Timeout>()

	const update = () => {
		if (timeout.current) {
			clearTimeout(timeout.current)
		}
		timeout.current = setTimeout(() => {
			dispatch(updateCheckout())
			setTimeout(() => {
				if (!ready) update()
			}, 2000)
		}, 2000)
	}

	useEffect(() => {
		if (!ready) {
			if (checkout) {
				update()
			} else {
				dispatch(createCheckout())
			}
		}
	}, [ready, checkout])

	useEffect(() => {
		document.body.style.overflowY = visible ? 'hidden' : 'scroll'
	}, [visible])

	useEffect(() => {
		const getVendors = async () => {
			const {
				data: { vendors }
			} = await strapi.query<{ vendors: Pick<Vendor, 'slug' | 'name' | 'minimumOrderValue'>[] }>({
				query: VENDORS
			})
			setVendors(vendors)
		}
		getVendors()
	}, [])

	return (
		<div onClick={hide} className={`${styles.wrapper} ${visible && styles.visible}`}>
			<div
				className={`${styles.container} ${visible && styles.containerVisible}`}
				onClick={e => e.stopPropagation()}
			>
				<div className={styles.title}>
					<a onClick={hide}>
						<CloseSquareOutlined className={styles.close} />
					</a>
					<Link href='/warenkorb'>
						<a>
							<Text className='semibold-24 grey-9'>Warenkorb</Text>
						</a>
					</Link>
				</div>
				{isEmpty ? (
					<div>
						<img src='/images/empty-cart-widget.png' className={styles.emptyImage} />
					</div>
				) : (
					<>
						<div className={styles.products}>
							{couponItems.length > 0 && (
								<div>
									<div className={styles.couponInfo}>
										<Text className='semibold-16 grey-9'>Versand per E-Mail</Text>
									</div>
									{couponItems.map((item, index) => (
										<div key={index} className={styles.item}>
											<Item item={item} size='small' />
										</div>
									))}
								</div>
							)}
							{sortedByVendor.map((item, index) => {
								const showVendorInfo =
									index === 0 || item.vendor !== sortedByVendor[index - 1].vendor
								return (
									<div key={item.id}>
										{showVendorInfo && (
											<div className={styles.vendor}>
												<VendorInfo vendor={item.vendor} vendors={vendors ?? []} size='small' />
											</div>
										)}
										<div className={styles.item}>
											<Item item={item} size='small' />
										</div>
									</div>
								)
							})}
						</div>
						<div className={styles.checkout}>
							<MinimumOrderValueAlert vendors={vendors ?? []} />
							<Spacer size={8} />
							<Row justify='space-between'>
								<Col>
									<Text className='regular-16 grey-9'>Lieferkosten</Text>
								</Col>
								<Col>
									<Text className='regular-16 grey-9'>kostenlos</Text>
								</Col>
							</Row>
							{Boolean(discountApplications && discountApplications[0]) && (
								<>
									<Spacer size={6} />
									<Row justify='space-between'>
										<Col>
											<Text className='regular-16 grey-9'>Rabatt</Text>
										</Col>
										<Col>
											<Text className='regular-16 grey-9'>
												-{formatPriceString(discountApplications![0].discountedAmount.amount)}
											</Text>
										</Col>
									</Row>
								</>
							)}
							<Divider className={styles.divider} />
							<Row justify='space-between'>
								<Col>
									<Text className='semibold-16 grey-9'>Summe </Text>
									<Text className='regular-10 grey-6'> inklusive Versand</Text>
								</Col>
								<Col>
									<Text className='semibold-16 grey-9'>
										{formatPriceString(checkout?.cost.totalAmount.amount ?? 0)}
									</Text>
								</Col>
							</Row>
							<Link href='/warenkorb'>
								<Button type='primary' size='large' block className={styles.button}>
									Zum Warenkorb
								</Button>
							</Link>
						</div>
					</>
				)}
			</div>
		</div>
	)
}

export default CartWidget
